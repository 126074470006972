import * as React from 'react';

import { useScrolledIntoView } from '../../hooks';
import { useSpring, animated } from 'react-spring';

import s from './CardImageBranding.module.scss';
import { Image as ImageType } from '../../types/';
import Link from '../Link';
import Image from "../Image";
import {MediaQuery} from "../Image/Image";
import ReactPlayer from 'react-player';

export interface CardImageBrandingProps {
    image?: ImageType;
    tag?: string;
    title?: string;
    text?: string;
    link?: any;
    pageType?: string;
    mediaQueries?: Array<MediaQuery>;
    listVideo?: string,
    autoplay?: boolean,
}

const NATIVE_VIDEO_FORMATS = ['.mp4', '.webm'];

const CardImageBranding: React.FC<CardImageBrandingProps> = (props) => {
    const {
        image,
        title,
        link,
        text,
        mediaQueries,
        listVideo,
        autoplay = true,
    } = props;

    const linkTitle = title || text;

    const [ViewSensor, isInView] = useScrolledIntoView({ partialVisibility: true });
    const fadeInProps = useSpring({
        opacity: isInView ? 1 : 0.001,
        transform: isInView
            ? `translate3d(0, 0, 0)`
            : `translate3d(0, 60px, 0)`
    });

    const useNativeVideoPlayback = typeof listVideo !== 'undefined'
        ? NATIVE_VIDEO_FORMATS
            .map(format => listVideo.endsWith(format))
            .filter(v => !!v)
            .length > 0
        : false;

    const isGif = listVideo && (listVideo.endsWith('.gif') || listVideo.endsWith('webp'))

    return (
        <ViewSensor>
            <animated.article className={s.Root} style={fadeInProps}>
                <div className={s.Figure}>
                    {image && (
                        <Image
                            isGif={isGif}
                            listVideo={listVideo}
                            image={image.renditions.small}
                            className={s.Image}
                            mediaQueries={mediaQueries}
                        />
                    )}
                    {listVideo && !isGif && useNativeVideoPlayback &&
                        <div className={s.NativePlayerWrapper}>
                            <video
                                src={listVideo}
                                {...(autoplay
                                    ? {
                                        playsInline: true,
                                        autoPlay: true,
                                        muted: true,
                                        loop: true,
                                        controls: false
                                    }
                                    : {
                                        playsInline: false,
                                        autoPlay: false,
                                        muted: false,
                                        loop: false,
                                        controls: false
                                    }
                                )}
                            />
                        </div>
                    }
                    {listVideo && !isGif && !useNativeVideoPlayback &&
                        <div className={s.ExternalPlayerWrapper}>
                            <ReactPlayer
                                className={s.Player}
                                width="100%"
                                height="100%"
                                url={listVideo}
                                {...(autoplay
                                    ? {
                                        loop: true,
                                        muted: true,
                                        playsinline: true,
                                        playing: true,
                                        controls: false
                                    }
                                    : {
                                        loop: false,
                                        muted: false,
                                        playsinline: false,
                                        playing: false,
                                        controls: true
                                    }
                                )}
                            />
                        </div>
                    }
                    <h3 className={s.Caption}>
                        <span dangerouslySetInnerHTML={{ __html: linkTitle }} />
                    </h3>

                    {link && <Link {...link} className={s.Link}>
                        <span className="sr-only">{linkTitle}</span>
                    </Link>}
                </div>
            </animated.article>
        </ViewSensor>
    );
};

export default CardImageBranding;
